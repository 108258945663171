import React from 'react'
import { XIcon } from '@heroicons/react/outline'

interface IPopUpProps {
  content: React.ReactNode
  handleClose: () => void
}

const Popup: React.FC<IPopUpProps> = ({ content, handleClose }) => (
  <div className="popup-box">
    <div className="box-small md:px-8">
      <span
        className="close-icon"
        onClick={handleClose}
        onKeyUp={handleClose}
        role="button"
        tabIndex={0}>
        <XIcon />
      </span>
      {content}
    </div>
  </div>
)

export default Popup
