import React, { useContext } from 'react'

import { IShopifyLineItem } from '@lib/shopify/interfaces'
import Item from './ShoppingCart/Item'
import ShopContext from '../../../context/ShopContext'

interface IShoppingCart {
  interactive: boolean
}

const ShoppingCart: React.FC<IShoppingCart> = ({ interactive }) => {
  const { checkout } = useContext(ShopContext)

  if (checkout.lineItems.length === 0) {
    return (
      <div className="text-center text-gray-300 font-bold text-lg">
        <span className="block px-16 py-20">Noch kein Produkt im Warenkorb</span>
        {/* {coupon && <CouponItem />} */}
      </div>
    )
  }

  return (
    <div className="px-4 sm:px-8 lg:px-16 pb-4 md:pb-16 divide-y">
      {checkout.lineItems.map((lineItem: IShopifyLineItem) => (
        <Item lineItem={lineItem} interactive={interactive} key={lineItem.id} />
      ))}

      {/* {coupon && <CouponItem />} */}
    </div>
  )
}

export default ShoppingCart
