/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'

import Head from 'next/head'
import { useRouter } from 'next/router'

import { CookieSerializeOptions, serialize } from 'cookie'
import FreeShippingBanner from './FreeShippingBanner'
import { trackCookieConsentGiven } from '../../utils/tracking'
import Header from './header/Header'
import TrackingScript from './tracking/TrackingScript'
import TrackingIframe from './tracking/TrackingIframe'
import Alert from '../../components/shared/Alert'
import { IMenuItem } from './header/navigation/menu.model'
import CookieBanner from '../../components/shared/CookieBanner'
import Footer from './footer/Footer'
import ExitIntentPopUp from '../../components/shared/ExitIntentPopup'

interface ILayout {
  description: string
  metaImage?: string
  metaItemPropDescription?: string
  metaOgType?: string
  metaProductLink?: string
  metaProductPrice?: string
  title: string
  indexable?: boolean
  preview?: boolean
  topMenu: IMenuItem[]
}

const COOKIE_PREFIX = '_yoself'

const getCookies = () =>
  document.cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent)
    try {
      return Object.assign(res, { [key]: JSON.parse(val) })
    } catch (e) {
      return Object.assign(res, { [key]: val })
    }
  }, {})

const setNoConsentCookie = () => {
  const options: CookieSerializeOptions = {
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
    secure: true,
    path: '/'
  }

  document.cookie = serialize(COOKIE_PREFIX, `no-consent`, options)
}

const Layout: React.FC<ILayout> = ({
  children,
  description,
  indexable = true,
  metaImage,
  metaItemPropDescription,
  metaOgType = 'website',
  metaProductLink,
  metaProductPrice,
  preview = false,
  title,
  topMenu
}) => {
  useEffect(() => {
    fetch('/api/create-session')
  }, [])
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  const onCookieConfirmed = () => {
    trackCookieConsentGiven()

    fetch('/api/cookie', {}).then(() => {
      setShowCookieBanner(false)
    })
  }

  const onCookieDeclined = () => {
    setNoConsentCookie()
    setShowCookieBanner(false)
  }

  const canonicalURL = `https://yoself.de${useRouter().asPath}`

  useEffect(() => {
    // check if cookie exists
    const cookies = getCookies()

    if (COOKIE_PREFIX in cookies === false) {
      setShowCookieBanner(true)
    }
  })

  return (
    <>
      <Head>
        <TrackingScript />
        {/* NOTE: HTML Meta Tags */}
        <title>{title}</title>
        <meta name="description" content={description} key="description" />
        <meta
          name="robots"
          content={
            indexable === true && process.env.NEXT_PUBLIC_INDEX !== 'false'
              ? 'index,follow'
              : 'noindex,follow'
          }
          key="robots"
        />
        <meta name="revisit-after" content="3 days" key="revisit_after" />

        {/* NOTE: Google / Search Engine Tags */}
        <meta itemProp="name" content={title} key="prop_name" />
        <meta
          itemProp="description"
          content={metaItemPropDescription || description}
          key="prop_description"
        />
        {metaImage && <meta itemProp="image" content={metaImage} key="meta_image" />}
        <meta itemProp="isFamilyFriendly" content="True" key="is_family_friendly" />

        {/* NOTE: Facebook Meta Tags */}
        <meta property="og:site_name" content="yoself.de" key="og_site_name" />
        <meta property="og:url" content={canonicalURL} key="og_url" />
        <meta property="og:type" content={metaOgType} key="og_type" />
        <meta property="og:title" content={title} key="og_title" />
        <meta property="og:description" content={description} key="og_description" />
        {metaImage && <meta property="og:image" content={metaImage} key="og_image" />}

        {/* NOTE: Product Meta Tags */}
        <meta property="product:brand" content="The Greenest Greens" key="product_brand" />
        {metaProductPrice && (
          <>
            <meta
              property="product:price:amount"
              content={metaProductPrice}
              key="product_price_amount"
            />
            <meta property="product:price:currency" content="EUR" key="product_price_currency" />
          </>
        )}
        {metaProductLink && (
          <meta property="product:product_link" content={metaProductLink} key="product_link" />
        )}

        {/* NOTE: Twitter Meta Tags */}
        <meta name="twitter:card" content="" key="twitter_card" />
        <meta name="twitter:site" content="yoself.de" key="twitter_site" />
        <meta name="twitter:title" content={title} key="twitter_title" />
        <meta name="twitter:description" content={description} key="twitter_description" />
        {metaImage && <meta name="twitter:image" content={metaImage} key="twitter_image" />}

        <link rel="canonical" href={canonicalURL} />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="../../images/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="../../images/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="../../images/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="../../images/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="../../images/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="../../images/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="../../images/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="../../images/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="../../images/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="../../images/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="../../images/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="../../images/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="../../images/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="../../images/favicon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="../../images/favicon/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <TrackingIframe />

      <div className="flex flex-col font-main">
        {preview ? <Alert /> : null}

        <FreeShippingBanner />

        <Header topMenu={topMenu} />

        <main className="flex-grow font-main">{children}</main>

        {showCookieBanner ? (
          <CookieBanner onConfirmClicked={onCookieConfirmed} onDeclinedClick={onCookieDeclined} />
        ) : (
          <></>
        )}

        <Footer />
      </div>

      <ExitIntentPopUp />
    </>
  )
}

export default Layout
