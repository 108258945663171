import React from 'react'

interface IContainer {
  // eslint-disable-next-line react/require-default-props
  className?: string
}

const Container: React.FC<IContainer> = ({ children, className }) => (
  <div className={`${className} max-w-site mx-auto`}>{children}</div>
)

export default Container
