import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { IMenuItem, IMenuLevel } from '../menu.model'

const MenuLevelTwo: React.FC<IMenuLevel> = ({ menuItems, parentItem, closeMenu }) => {
  const MenuItem: React.FC<IMenuItem> = ({ id, externalLink, image, title, aspectRatio }) => (
    <li
      key={`level2-${id}`}
      className={`whitespace-nowrap overflow-hidden md:w-32 text-left ${
        aspectRatio === 'landscape' ? 'col-span-2 md:w-64' : ''
      }`}>
      <Link href={externalLink} prefetch={false}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a href={externalLink} onClick={closeMenu}>
          <div className={`aspect-${aspectRatio}`}>
            <Image
              className="brightness-95"
              src={image.responsiveImage.src}
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL={image.responsiveImage.base64}
              objectPosition={`${image.focalPoint.x * 100}% ${image.focalPoint.y * 100}%`}
            />
          </div>
          <span className="overflow-hidden text-ellipsis whitespace-nowrap block text-sm mt-2">
            {title}
          </span>
        </a>
      </Link>
    </li>
  )

  return (
    <ul className="grid grid-cols-3 gap-2 pb-5 md:absolute md:left-0 md:w-full md:justify-center bg-white md:shadow-2xl-soft md:hidden border-b border-gray-300">
      {parentItem.externalLink ? (
        <MenuItem
          id={parentItem.id}
          aspectRatio={parentItem.aspectRatio}
          title="Zur Marke"
          externalLink={parentItem.externalLink}
          image={parentItem.image}
        />
      ) : null}
      {menuItems.map((menuItemChild) => (
        <MenuItem
          key={menuItemChild.id}
          id={menuItemChild.id}
          externalLink={menuItemChild.externalLink}
          aspectRatio={menuItemChild.aspectRatio}
          title={menuItemChild.title}
          image={menuItemChild.image}
        />
      ))}
    </ul>
  )
}
export default MenuLevelTwo
