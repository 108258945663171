import React from 'react'
import Navigation from './navigation/Navigation'
import { IMenuItem } from './navigation/menu.model'
import SlideOver from '../../checkout/components/ShoppingCart/SlideOver'

interface IHeader {
  topMenu: IMenuItem[]
}

const Header: React.FC<IHeader> = ({ topMenu }) => (
  <header className="z-50 sticky top-0 bg-white">
    <Navigation topMenu={topMenu} />
    <SlideOver />
  </header>
)

export default Header
