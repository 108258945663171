import React from 'react'

const ArrowToRightIcon: React.FC = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="20.1782"
    viewBox="0 0 30 20.1782">
    <g id="Gruppe_109" data-name="Gruppe 109">
      <line
        id="Linie_67"
        data-name="Linie 67"
        x1="2.9795"
        y1="10.001"
        x2="25.5645"
        y2="10.001"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <g id="Gruppe_90" data-name="Gruppe 90">
        <line
          id="Linie_65"
          data-name="Linie 65"
          x1="27.0205"
          y1="10"
          x2="19.7355"
          y2="16.508"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Linie_66"
          data-name="Linie 66"
          x1="27.0205"
          y1="10"
          x2="19.7355"
          y2="3.492"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default ArrowToRightIcon
