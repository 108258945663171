// NOTE: To avoid floating point issues, we are using the currency.js library here.
import currency from 'currency.js'

export const toEuro = (amount: number): string =>
  currency(amount, { symbol: '€', separator: '.', decimal: ',', pattern: '# !' }).format()

export const shopifyToEuro = (amount: number): string =>
  currency(amount, { symbol: '€', separator: '.', decimal: '.', pattern: '# !' }).format({
    decimal: ','
  })

export const toDecimals = (amount: number): string => currency(amount).value.toFixed(2).toString()

export const toCents = (amount: string | number): number => currency(amount).intValue
