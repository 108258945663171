import React from 'react'

interface IFreeShippingBanner {
  // eslint-disable-next-line react/require-default-props
  backgroundColor?: string
}

const FreeShippingBanner: React.FC<IFreeShippingBanner> = ({ backgroundColor }) => (
  <div
    className={` py-2 font-medium text-center text-white text-sm ${
      !backgroundColor ? 'bg-black' : backgroundColor
    }`}>
    Versandkostenfreie Lieferung
  </div>
)

export default FreeShippingBanner
