import { IShopifyLineItem } from '@lib/shopify/interfaces'
import selectInfoFromCartProducts from '@context/selectors'
import { ICartItem } from '@modules/products/interfaces/productInterfaces'
import trackEvent from './trackEvent'

const trackBeginCheckoutEvent = (lineItems: IShopifyLineItem[], cartItems: ICartItem[]): void => {
  trackEvent({
    event: 'begin_checkout',
    ecommerce: {
      items: lineItems.map((lineItem) => {
        const { brand } = selectInfoFromCartProducts(cartItems, lineItem)

        return {
          item_brand: brand,
          item_id: lineItem.variant.id,
          item_name: lineItem.title,
          price: lineItem.variant.priceV2.amount,
          quantity: lineItem.quantity
        }
      })
    }
  })

  trackEvent({
    event: 'ua_checkout',
    ecommerce: {
      currencyCode: 'EUR',
      checkout: {
        actionField: {
          option: 'begin_checkout',
          step: 1
        },
        products: lineItems.map((lineItem: any) => {
          const { brand } = selectInfoFromCartProducts(cartItems, lineItem)
          return {
            brand,
            category: '',
            coupon: '',
            id: lineItem.variant.sku,
            name: lineItem.title,
            price: lineItem.variant.priceV2.amount,
            quantity: lineItem.quantity,
            variant: lineItem.variant.sku
          }
        })
      }
    }
  })
}

export default trackBeginCheckoutEvent
