import React from 'react'
import Link from 'next/link'
import { IMenuLevel } from '../menu.model'
import MenuLevelTwo from './MenuLevelTwo'

const MenuLevelOne: React.FC<IMenuLevel> = ({ menuItems, closeMenu }) => (
  <ul className="-mx-4 w-screen relative order-last md:order-2 flex flex-col overflow-y-auto p-3 pb-16 menu-height bg-white md:mt-0 md:flex-row md:justify-center md:h-auto md:w-auto md:overflow-y-visible md:p-0 md:ml-0 border-t md:border-t-0 border-gray-300">
    {menuItems.map((menuItemChild) => (
      <li
        key={menuItemChild.id}
        className="whitespace-nowrap md:first:flex-grow md:last:flex-grow md:first:text-right md:last:text-left">
        {menuItemChild.externalLink ? (
          <Link href={menuItemChild.externalLink} prefetch={false}>
            <a
              onClick={closeMenu}
              href={menuItemChild.externalLink}
              className="menu-level-one-link">
              {menuItemChild.title}
            </a>
          </Link>
        ) : (
          <div className="menu-level-one-link">{menuItemChild.title}</div>
        )}
        <MenuLevelTwo
          menuItems={menuItemChild.children}
          parentItem={menuItemChild}
          closeMenu={closeMenu}
        />
      </li>
    ))}
  </ul>
)

export default MenuLevelOne
