import React, { useState, useEffect } from 'react'

import Popup from './ModalSmall'

const ExitIntentPopUp: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleCouponPopup = () => {
    setIsOpen(!isOpen)
  }

  const showPopUp = () => {
    setIsOpen(true)
  }

  useEffect(() => {
    window.addEventListener('open-exit-intent-popup', showPopUp)
    return () => window.removeEventListener('open-exit-intent-popup', showPopUp, true)
  }, [])

  return (
    <div>
      <button
        type="button"
        className="flex mt-16 mx-auto"
        value="Video öffnen"
        onClick={toggleCouponPopup}
        onKeyUp={toggleCouponPopup}
        aria-label="Open"
      />
      {isOpen && (
        <Popup
          content={
            <>
              <div className="max-w-content-sm mx-auto md:pt-10">
                <iframe
                  title="newsletter"
                  className="mj-w-res-iframe h-520px sm:h-440px"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                  src={
                    process.env.NEXT_PUBLIC_NEWSLETTER_ENDPOINT
                      ? process.env.NEXT_PUBLIC_NEWSLETTER_ENDPOINT.trim()
                      : null
                  }
                  width="100%"
                  height="480"
                />

                <script
                  type="text/javascript"
                  src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
                />
              </div>
            </>
          }
          handleClose={toggleCouponPopup}
        />
      )}
    </div>
  )
}

export default ExitIntentPopUp
