import React from 'react'

import Link from 'next/link'

const Footer: React.FC = () => (
  <footer>
    <div className="bg-white px-3 lg:px-8 py-8 text-black border-t lg:border-t-0 border-gray-300 pb-8">
      <div className="flex flex-wrap w-full justify-start md:justify-center lg:justify-between text-center max-w-site mx-auto px-2">
        <div className="flex flex-wrap text-left md:text-center lg:text-left pb-4 lg:pr-16 w-full lg:w-auto justify-start md:justify-center lg:justify-start">
          <div>
            Du hast noch Fragen?
            <br />
            Wir sind gern für dich da unter <br />{' '}
            <span className="font-semibold mb-2 inline">
              <a className="inline" href="tel:+4991799440400">
                +49 (0) 9179 9440400
              </a>
            </span>
            <p className="inline"> und</p>
            <br />
            <span className="font-semibold mb-2 inline">
              <a className="inline" href="mailto:hello@yoself.de">
                hello@yoself.de
              </a>
            </span>
          </div>
        </div>
        <div className="flex flex-wrap justify-start md:justify-center lg:justify-end w-full lg:w-auto mt-8 lg:mt-0">
          <ul className="gap-4 md:gap-8 space-y-4 md:space-y-0 md:grid md:grid-flow-col md:grid-rows-1 w-auto text-left md:text-center lg:text-right">
            <li>
              <Link href="/agb">
                <a className="hover:underline" href="/agb">
                  AGB
                </a>
              </Link>
            </li>
            <li>
              <Link href="/datenschutz">
                <a className="hover:underline" href="/datenschutz">
                  Datenschutz
                </a>
              </Link>
            </li>
            <li>
              <Link href="/widerruf">
                <a className="hover:underline" href="/widerruf">
                  Widerrufsbelehrung
                </a>
              </Link>
            </li>
            <li>
              <Link href="/impressum">
                <a className="hover:underline" href="/impressum">
                  Impressum
                </a>
              </Link>
            </li>
            <li>
              <Link href="../ueber-uns">
                <a className="hover:underline" href="/ueber-uns">
                  Über uns
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-wrap lg:justify-between w-full text-left md:text-center lg:text-right mt-12 md:mt-8 lg:mt-12">
          <p className="w-full lg:w-1/2 text-left md:text-center lg:text-left pb-4">
            {new Date().getFullYear()} – yoself.de
          </p>
          <p className="text-gray-400 text-sm w-full lg:w-1/2 text-left md:text-center lg:text-right">
            Preisangaben inkl. gesetzl. MwSt., versandkostenfrei
            <br />
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
