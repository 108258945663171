import React from 'react'
import daysjs from 'dayjs'
import { StockStatus } from '@modules/products/interfaces/productInterfaces'
import { DEFAULT_DELIVERY_TIME } from '@utils/constants'

export interface IDeliveryTextContent {
  stockStatus: StockStatus
  deliveryTime: string
  availabilityDate?: string
}

export const formatDeliveryDate = (date: string): string => daysjs(date).format('DD.MM.YYYY')

const DeliveryTextContent: React.FC<IDeliveryTextContent> = ({
  stockStatus,
  availabilityDate,
  deliveryTime
}) => {
  let text: string
  let color: string

  switch (stockStatus) {
    case 'onbackorder':
      text = availabilityDate
        ? `Ab dem ${formatDeliveryDate(availabilityDate)} lieferbar.<br/>Jetzt vorbestellen.`
        : 'Aktuell ausverkauft.<br/>Jetzt vorbestellen'

      color = 'text-green-600'

      break
    case 'outofstock':
      text = 'ausverkauft'
      color = 'text-red-500'

      break
    default:
      text = `Sofort lieferbar,<br/>Lieferzeit ${deliveryTime || DEFAULT_DELIVERY_TIME}`
      color = 'text-green-600'
  }

  return (
    <>
      <div className="hidden md:flex w-full lg:pl-4 lg:pr-8">
        <p
          className={`block w-full pt-2 font-bold ${color} leading-snug`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>

      <p
        className={`block md:hidden w-full pt-2 pb-4 md:pb-6 font-bold ${color} text-xs leading-snug`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </>
  )
}

export default DeliveryTextContent
